@import "~jsoneditor/dist/jsoneditor.min.css";
html {
  overflow-y: initial !important;
}

html.cdk-global-scrollblock body {
  overflow-y: scroll;
}

body {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0;
}

main {
  margin: 10px;
}

.hide {
  display: none;
}

.clickable {
  cursor: hand;
}

.wrap, .mat-list-wrap-title {
  white-space: normal;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-list-wrap-title {
  margin-bottom: -10px;
}

.list-item-meta-div {
  padding: 10px 16px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
}

.mat-mdc-list.dense-detail-list .mat-mdc-list-item-title {
  font-size: 12px;
}
.mat-mdc-list.dense-detail-list .mat-mdc-list-item-line {
  font-size: 12px;
}

.mat-mdc-card-title {
  padding-bottom: 10px;
}

.header-text {
  font-size: 18px;
  font-weight: 500;
}

.mat-multiline-tooltip {
  white-space: pre-line;
  max-width: 500px !important;
}

mat-form-field.serialFormField {
  width: 200px;
}

mat-form-field.xLFormField {
  width: 250px;
}

img.verification-image {
  width: 80%;
}

.dark-toolbar h3 {
  color: white;
}
.dark-toolbar mat-icon {
  color: white;
}

.selectPopupFull {
  min-width: max-content;
}