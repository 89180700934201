@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);


  .fc .fc-col-header-cell-cushion {
    color: mat.m2-get-color-from-palette($foreground, secondary-text) !important;
  }

  .fc-timegrid-slot-label {
    &.store-time {
      color: mat.m2-get-color-from-palette($foreground, secondary-text) !important;
    }

    &.local-time {
      color: mat.m2-get-color-from-palette($foreground, hint-text) !important;
    }
  }

  .fc-day-today {
    background: mat.m2-get-color-from-palette($background, disabled-button) !important;
  }

  .fc-timegrid td {
    border-color: grey;
  }

  .fc-timegrid-slot-minor {
    border-top-color: #8B8B8B66 !important;
  }

  .fc-list-event:hover td {
    background-color: mat.m2-get-color-from-palette($accent) !important;
  }

  .fc .fc-list-empty {
    background-color: mat.m2-get-color-from-palette($background, background);
  }

  .fc-theme-standard .fc-list-day-cushion {
    background-color: mat.m2-get-color-from-palette($primary);
  }

  .fc-list-day-text, .fc-list-day-side-text {
    color: mat.m2-get-color-from-palette($foreground, text) !important;
  }

  .fc-button-primary {
    background-color: mat.m2-get-color-from-palette($primary, 500) !important;
  }

 .fc-button-primary:hover {
    background-color: mat.m2-get-color-from-palette($primary, 400) !important;
  }

  .fc-daygrid-event {
    color: mat.m2-get-color-from-palette($foreground, text) !important;
  }

  .event-tooltip {
    background: mat.m2-get-color-from-palette($background, dialog);
  }

  .fc-theme-standard th {
    border-color: mat.m2-get-color-from-palette($foreground, secondary-text) !important;
  }

  .fc-theme-standard .fc-scrollgrid {
    border-color: mat.m2-get-color-from-palette($foreground, secondary-text) !important;
  }

  .fc-theme-standard td {
    border-color: mat.m2-get-color-from-palette($foreground, hint-text);
  }

  .fc-daygrid-day-number {
    color: mat.m2-get-color-from-palette($primary);
  }

  .instruction-header {
    color: mat.m2-get-color-from-palette($accent);
  }

  .instruction {
    color: mat.m2-get-color-from-palette($foreground, hint-text);
  }

  .local-time {
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }

  .jsoneditor-menu {
    background-color: mat.m2-get-color-from-palette($primary) !important;
  }

  .jsoneditor-contextmenu .jsoneditor-menu {
    background-color: white !important;
  }

  .jsoneditor-contextmenu .jsoneditor-menu .jsoneditor-selected {
    background-color: gray !important;
  }
}
