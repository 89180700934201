@use 'sass:map';
@use '@angular/material' as mat;

//noinspection CssUnknownTarget
@import "~jsoneditor/dist/jsoneditor.min.css";

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  a {
    color: mat.m2-get-color-from-palette($primary, 400);
    text-decoration: none;

    &:hover, &:focus {
      color: mat.m2-get-color-from-palette($primary, 300);
    }
  }

  .selected {
    background-color: mat.m2-get-color-from-palette($accent) !important;

    a {
      color: mat.m2-get-color-from-palette($background, dialog) !important;
      font-weight: bold !important;
    }
  }

  .warn {
    color: mat.m2-get-color-from-palette($warn) !important;
  }

  // Angular Split
  .bt-split-gutter {
    width: 100%;
    height: 100%;
    background-color: mat.m2-get-color-from-palette($foreground, divider);
    position: relative;
  }

  .bt-split-gutter-icon {
    height: 100%;
    width: 100%;
    background-color: black;
    transition: opacity 0.3s;
    opacity: 0;
    position: absolute;

    .dragged &,
    &:hover {
      opacity: 0.2;
      z-index: 100;
    }

    $shadeSize: 8px;

    .as-horizontal & {
      width: 1 + ($shadeSize * 2);
      height: 100%;
      left: -$shadeSize;
      right: $shadeSize;
    }

    .as-vertical & {
      height: 1 + ($shadeSize * 2);
      width: 100%;
      top: -$shadeSize;
      bottom: $shadeSize;
      left: 0;
    }
  }

  .reset-status-message{
    color: mat.m2-get-color-from-palette($warn, 300);
  }
}

//////////////////////////

html {
  overflow-y: initial !important;
}

html.cdk-global-scrollblock body {
  overflow-y: scroll;
}

body {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0;
}

main {
  margin: 10px;
}

.hide {
  display: none;
}

.clickable {
  cursor: hand;
}

.wrap {
  white-space: normal;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-list-wrap-title {
   @extend .wrap;

   margin-bottom: -10px;
 }

.list-item-meta-div {
  padding: 10px 16px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
}

.mat-mdc-list.dense-detail-list {
  .mat-mdc-list-item-title {
    font-size: 12px;
  }

  .mat-mdc-list-item-line {
    font-size: 12px;
  }
}

.mat-mdc-card-title {
  padding-bottom: 10px;
}

.header-text {
  font-size: 18px;
  font-weight: 500;
}

.mat-multiline-tooltip {
  white-space: pre-line;
  max-width: 500px !important;
}

mat-form-field.serialFormField {
  width: 200px;
}

mat-form-field.xLFormField {
  width: 250px;
}

img.verification-image {
  width: 80%;
}

.dark-toolbar {
    h3 {
      color: white;
    }
    mat-icon {
      color: white;
    }
}

// Used to make MatSelect popups fit content width, not bound by width of the MatSelect itself
.selectPopupFull {
  min-width: max-content;
}
