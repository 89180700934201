@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .path-list-header {
    padding: 12px 10px;
    border-bottom: solid 1px mat.m2-get-color-from-palette($foreground, divider);;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    color: mat.m2-get-color-from-palette($foreground, text);
    font-size: 12px;
    font-weight: bold;
  }

  .path-list-element {
    padding: 12px 10px;
    border-bottom: solid 1px mat.m2-get-color-from-palette($foreground, divider);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    font-size: 14px;
  }
}
