@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .mat-mdc-row:hover .mat-mdc-cell {
    background-color: mat.m2-get-color-from-palette($foreground, base, .1) !important;
  }

  .duration {
    font-size: x-small;
    font-style: italic;
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }
}
