@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .info-panel{
    background-color: mat.m2-get-color-from-palette($background, hover) !important;
  }

  .jsoneditor-menu {
    background-color: mat.m2-get-color-from-palette($primary);
  }

  .jsoneditor {
    border: solid 1px mat.m2-get-color-from-palette($foreground, dividers) !important;
  }

  .image-container {
    background: mat.m2-get-color-from-palette($background, app-bar);
  }
}
