@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .zone-list {
    .mat-toolbar {
      background-color: mat.m2-get-color-from-palette($primary);
    }
  }

  .store-zones-container {
    margin: -10px;
    background: mat.m2-get-color-from-palette($background, dialog);
  }
}
